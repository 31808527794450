<template>
  <div class="set-custom-value">
    <label class="set-custom-value--label">{{ concept }}</label>
    <div class="set-custom-value--group">
      <input
        type="text"
        class="form-control form-control-sm"
        :value="currentValue"
        @change="changeValue"
        @keyup="changeValue"
        @blur="checkFieldValue"
        @reset-value="resetValue"
        pattern="\d*"
        :maxlength="inputMaxLength ? inputMaxLength : 2"
      />

      <CButton
        color="success"
        size="sm"
        class="btn-brand fadeIn anim-speed--1s"
        v-show="changing"
        @click="openWarning"
      >
        <CIcon name="cil-save" />
      </CButton>

      <CButton
        color="danger"
        size="sm"
        class="btn-brand fadeIn anim-speed--1s"
        v-show="changing"
        @click="setInitialValue"
      >
        <CIcon name="cil-x-circle"
      /></CButton>

      <CModal
        :title="warningModal.title"
        color="warning"
        :show.sync="warningModalShow"
        class="modal-vertical-centered"
        size="sm"
      >
        <p>{{ warningModal.message }}</p>
        <span slot="footer">
          <CButton
            color="secondary"
            @click="warningModalShow = false"
            style="margin-right: 1rem"
            >Cancelar</CButton
          >
          <CButton color="warning" @click="setNewValue">Aceptar</CButton>
        </span>
      </CModal>
    </div>
  </div>
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "SetNumberInput",
  data() {
    return {
      changing: false,
      fieldValue: null,
      newInitValue: null,
      warningModalShow: false,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    currentValue() {
      return this.fieldValue === null ? this.initValue : this.fieldValue;
    },
    newValue: {
      get() {
        return this.newInitValue;
      },
      set(value) {
        this.newInitValue = value;
      },
    },
    warningModal() {
      return {
        title: this.messages.dailyReport.warningTitle,
        message: `${
          this.messages.dailyReport.warningDefaultMessage1
        } ${this.concept.toLowerCase()} ${
          this.messages.dailyReport.warningDefaultMessage1
        }.`,
      };
    },
  },
  props: {
    initValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: false,
    },
    concept: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    inputMaxLength: {
      type: Number,
      required: false,
    },
  },
  methods: {
    changeValue(ev) {
      this.fieldValue = ev.target.value;
      this.changing = this.validateFieldValue(ev.target.value);
    },
    setInitialValue() {
      this.fieldValue = this.newInitValue ? this.newInitValue : this.initValue;
      this.changing = false;
    },
    resetValue(value) {
      this.fieldValue = value;
    },
    openWarning() {
      this.warningModal.message = `${
        this.messages.dailyReport.warningMessage1
      } ${this.concept.toLowerCase()} ${
        this.messages.dailyReport.warningMessage2
      } ${this.fieldValue} ${this.messages.dailyReport.warningMessage3}.`;
      this.warningModalShow = true;
    },
    setNewValue() {
      // Send the current value to API
      const valueToSend = this.fieldValue - this.initValue;

      this.warningModalShow = false;
      this.newInitValue = this.currentValue;
      this.changing = false;

      const countryCodeValue = !this.$store.getters.getFiltersAreaComboParams
        ? JSON.parse(localStorage.user).countryCode
        : this.$store.getters.getFiltersAreaComboParams.countryCode
        ? this.$store.getters.getFiltersAreaComboParams.countryCode
        : JSON.parse(localStorage.user).countryCode;

      const commonParams = {
        countryCode: countryCodeValue,
        fiscalYear: this.$store.getters.getFilterFYValue,
        month: this.$store.getters.getFilterMonthValue,
      };

      let modifierParam;

      switch (this.type) {
        case "invoiceDays":
          modifierParam = {
            invoiceDaysToAdd: valueToSend,
          };
          break;
        case "workingDays":
          modifierParam = {
            workingDaysToAdd: valueToSend,
          };
          break;
      }

      const params = {
        ...commonParams,
        ...modifierParam,
      };

      Api.sendPost("nibsa/updateCalendarModifier", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.$emit("reload-tables");
            return true;
          }
        }
      });
    },
    checkFieldValue() {
      if (!this.validateFieldValue(this.currentValue)) this.setInitialValue();
    },
    validateFieldValue(value) {
      const maxValue = this.maxValue === undefined ? 30 : this.maxValue;
      return value > 0 && value <= maxValue;
    },
  },
};
</script>
