<template>
  <div class="set-custom-value">
    <label class="set-custom-value--label">{{ concept }}</label>
    <div class="set-custom-value--group">
      <input
        type="text"
        class="form-control form-control-sm wide"
        :value="currentValue"
        @change="changeValue"
        @keyup="changeValue"
        @blur="checkFieldValue"
        @reset-value="resetValue"
      />

      <CButton
        color="success"
        size="sm"
        class="btn-brand fadeIn anim-speed--1s"
        v-show="changing"
        @click="openWarning"
      >
        <CIcon name="cil-save"
      /></CButton>

      <CButton
        color="danger"
        size="sm"
        class="btn-brand fadeIn anim-speed--1s"
        v-show="changing"
        @click="setInitialValue"
      >
        <CIcon name="cil-x-circle"
      /></CButton>

      <CModal
        :title="warningModal.title"
        color="warning"
        :show.sync="warningModalShow"
        class="modal-vertical-centered"
        size="sm"
      >
        <p>{{ warningModal.message }}</p>
        <span slot="footer">
          <CButton
            color="secondary"
            @click="warningModalShow = false"
            style="margin-right: 1rem"
            >Cancelar</CButton
          >
          <CButton color="warning" @click="setNewValue">Aceptar</CButton>
        </span>
      </CModal>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetNumberInput",
  data() {
    return {
      changing: false,
      fieldValue: null,
      newInitValue: null,
      warningModalShow: false,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    currentValue() {
      return this.fieldValue === null ? this.initValue : this.fieldValue;
    },
    newValue: {
      get() {
        return this.newInitValue;
      },
      set(value) {
        this.newInitValue = value;
      },
    },
    warningModal() {
      return {
        title: this.messages.dailyReport.warningTitle,
        message: `${
          this.messages.dailyReport.warningDefaultMessage1
        } ${this.concept.toLowerCase()} ${
          this.messages.dailyReport.warningDefaultMessage1
        }.`,
      };
    },
  },
  props: {
    initValue: {
      type: String,
      required: true,
    },
    concept: {
      type: String,
      required: true,
    },
  },
  methods: {
    changeValue(ev) {
      this.fieldValue = ev.target.value;
      this.changing = this.validateFieldValue(ev.target.value);
    },
    setInitialValue() {
      this.fieldValue = this.newInitValue ? this.newInitValue : this.initValue;
      this.changing = false;
    },
    openWarning() {
      this.warningModal.message = `${
        this.messages.dailyReport.warningMessage1
      } ${this.concept.toLowerCase()} ${
        this.messages.dailyReport.warningMessage2
      } ${this.fieldValue}.`;
      this.warningModalShow = true;
    },
    setNewValue() {
      // Send the current value to API
      this.warningModalShow = false;
      this.newInitValue = this.currentValue;
      this.changing = false;
      this.$emit("set-custom-header", this.currentValue);
    },
    checkFieldValue() {
      if (!this.validateFieldValue(this.currentValue)) this.setInitialValue();
    },
    validateFieldValue(value) {
      return value.trim() !== "";
    },
    resetValue(value) {
      this.fieldValue = value;
    },
  },
};
</script>
